import React, { useState, useRef } from 'react'
import { Formik } from 'formik'
import axios from 'axios'
import InputMask from "react-input-mask"

const Modal = ({ id, title, name, forwardRef, isOpen, isResults, transactionID, product_code }) => {

    const env = process.env;
    const checkboxHandler = target => target.checked ? target.setAttribute('value', 1) : target.setAttribute('value', 0)
    const phoneRef = useRef()

    // console.log( VMasker );
    return (
        <div id={isResults ? 'resultsModal' : null} ref={forwardRef} className={isOpen ? 'modal open-modal' : 'modal'}>
            <div className='modal-content'>

                <Formik
                    initialValues={{ nombre: '', apellido: '', email: '', telefono: '', recibirinfo: 1, recibirinfoemail: 1, aceptotermcon: 1 }}
                    validate={values => {

                        const errors = {};

                        if (!values.nombre) {
                            errors.nombre = 'Required';
                            errors.nombre = 'Campo requerido';
                        }
                        if (!values.apellido) {
                            errors.apellido = 'Required';
                            errors.apellido = 'Campo requerido';
                        }
                        if (!values.telefono) {
                            errors.telefono = 'Required';
                        } else if (!/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i.test(values.telefono)) {
                            errors.telefono = 'Campo requerido';
                        }

                        if (!values.email) {
                            errors.email = 'Required';
                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                            errors.email = 'Campo requerido';
                        }

                        return errors;
                    }}
                    onSubmit={(values, { setSubmitting }) => {

                        const resultsModal = document.querySelector('#resultsModal')
                        const activeModal = document.querySelector('.open-modal')
                        const activeForm = activeModal.querySelector('form')
                        const formData = new FormData(activeForm)
                        const ManejoPrestamo = activeForm.getAttribute('name')
                        const phoneOptedinCheck = (formData.get("recibirinfo") === false ? "no_phone" : "opted-in_phone")
                        // append trasaction id for the update method
                        formData.append('transactionID', transactionID)
                        formData.append('product_code', product_code)
                        formData.append('manejoprestamo', ManejoPrestamo)
                        setSubmitting(true);

                        async function updateData() {

                            const updateReq = await axios.post(env.REACT_APP_UPDATE_ENDPOINT, formData)
                            const updateRes = await updateReq;

                            if (ManejoPrestamo === "Solicitar Prueba Manejo") {
                                //Push GTM
                                window.dataLayer.push({
                                    'event': 'formSubmitted',
                                    'formName': 'Test_Drive_onCalculator',
                                    'phoneOptedIn': phoneOptedinCheck,
                                });
                                //Push GTM
                            } else if (ManejoPrestamo === "Solicitar Estimado") {
                                //Push GTM
                                window.dataLayer.push({
                                    'event': 'formSubmitted',
                                    'formName': 'Estimate_onCalculator',
                                    'phoneOptedIn': phoneOptedinCheck,
                                });
                                //Push GTM
                            }


                            // close current modal
                            document.querySelector('.open-modal').classList.remove('open-modal')

                            // open results modal
                            resultsModal.classList.add('open-modal')

                            // Append results message
                            resultsModal.querySelector('#results-text').innerHTML = updateRes.data.message
                            setSubmitting(false);

                        } updateData()

                        setTimeout(() => {
                            resultsModal.classList.remove('open-modal')
                        }, 5000)

                    }}
                >

                    {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (


                        <form id={id} name={name} onSubmit={handleSubmit} className='w-full'>

                            <div className='modal-header'>
                                <h6>{title}</h6>
                                <button
                                    className='modal-close'
                                    type='button'
                                    onClick={() => { console.log(forwardRef.current.classList.remove('open-modal')) }} >
                                    <img src='./images/close.svg' alt='close' className='lg:scale-75' />
                                </button>
                            </div>

                            {isResults
                                ? <div className='modal-body'>
                                    <div className="modal-wrapper">
                                        <p id="results-text" className='col-span-12 text-xl'></p>
                                    </div>
                                </div>



                                : <div className='modal-body'>
                                    <div className='modal-wrapper'>


                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'nombre'}
                                                name={'nombre'}
                                                type={'text'}
                                                className={errors.nombre && touched.nombre && errors.nombre ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.nombre} />

                                            <label htmlFor="nombre">Nombre</label>
                                            {errors.nombre && touched.nombre && errors.nombre ? <small>* Campo requerido</small> : null}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'apellido'}
                                                name={'apellido'}
                                                type={'text'}
                                                className={errors.apellido && touched.apellido && errors.apellido ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.apellido} />

                                            <label htmlFor="apellido">Apellidos</label>
                                            {errors.apellido && touched.apellido && errors.apellido ? <small>* Campo requerido</small> : null}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <InputMask
                                                id={'telefono'}
                                                name={'telefono'}
                                                type={'tel'}
                                                mask="999-999-9999"
                                                className={errors.telefono && touched.telefono && errors.telefono ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.telefono} />
                                            {/* <input
                                                id={ 'telefono' }
                                                name={ 'telefono' }
                                                ref={ phoneRef }
                                                className={ errors.telefono && touched.telefono && errors.telefono ? 'input-error' : 'input' }
                                                // onChange={ handleChange }
                                                onBlur={ handleBlur }
                                                value={ values.telefono } /> */}

                                            <label htmlFor="telefono">Celular</label>
                                            {errors.telefono && touched.telefono && errors.telefono ? <small>* Campo requerido</small> : null}
                                        </div>

                                        <div className={'col-span-12 lg:col-span-6 field provider'}>
                                            <input
                                                id={'email'}
                                                name={'email'}
                                                type={'email'}
                                                className={errors.email && touched.email && errors.email ? 'input-error' : 'input'}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email} />

                                            <label htmlFor="email">Correo Electrónico</label>
                                            {errors.email && touched.email && errors.email ? <small>* Campo requerido</small> : null}
                                        </div>


                                    </div>
                                    <div className='modal-wrapper'>


                                        <label className='col-span-12 provider'>
                                            <input type='checkbox' name='recibirinfo' id='recibirinfo' value={1} onChange={e => checkboxHandler(e.target)} />
                                            <p>Acepto recibir información al número de teléfono provisto, vía llamadas, llamadas grabadas o de voz artificial
                                                y/o mensaje de texto, sobre productos y servicios de parte de Nissan de Puerto Rico, su distribuidora Motorambar, Inc.,
                                                y/o sus concesionarios autorizados.</p>
                                        </label>

                                        <label className='col-span-12 provider'>
                                            <input type='checkbox' name='recibirinfoemail' id='recibirinfoemail' value={1} onChange={e => checkboxHandler(e.target)} />
                                            <p>Suscribirme al servicio de correo electrónico sobre productos y servicios de Nissan.</p>
                                        </label>

                                        <label className='col-span-12 provider'>
                                            <input type='checkbox' name='aceptotermcon' id='aceptotermcon' value={1} onChange={e => checkboxHandler(e.target)} />
                                            <p>Acepto los <a href='https://es.nissan.pr/terminos.html' target='_blank' rel="noreferrer">Términos y Condiciones</a></p>
                                        </label>


                                    </div>
                                </div>
                            }

                            {!isResults &&
                                <div className='modal-footer'>
                                    <button type='submit' className='col-span-12 lg:col-span-6 btn single' disabled={isSubmitting}>
                                        Enviar información
                                        <img src="./images/arrow.svg" alt="arrow" />
                                    </button>
                                </div>
                            }

                        </form>
                    )}

                </Formik>


            </div>
        </div>
    )
}

export default Modal
